@import '//cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css';
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA.ttf) format('truetype');
}
html {
  font-family: 'Rubik';
}
body {
  font-family: 'Rubik';
  background-color: #eaf0f4;
  min-height: 100%;
}
body > #root > div {
  height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
p,
li,
a,
span {
  font-family: 'Rubik';
}
.bt-loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: rgba(255, 255, 255, 0.85) url(/src/assets/images/Spinner.gif) no-repeat center center;
}
.bt-hide {
  display: none;
}
.bt-show {
  display: block;
}
.srv-validation-message {
  color: #FF7285;
  font-size: 13px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-100 {
  padding-bottom: 100px;
}
.p-ht {
  /* min-height: 170px; */
}
.App {
  text-align: center;
}
.grey {
  color: #8a8b92;
}
.black {
  color: #3c3a36;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 15px;
}
.font-18 {
  font-size: 16px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ui.menu .item > a:not(.ui),
.ui.menu .item > p:only-child,
.ui.menu .text.item > * {
  line-height: 1;
}
.body-wrapper {
  padding-top: 81px;
  min-height: calc(100vh - 34vh);
}
.ui.search > .results {
  /* border-top: 4px solid #2e817e; */
  /* border-bottom: 4px solid #2e817e; */
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-group {
  position: relative;
}
.eye-btn {
  position: absolute;
top: 0;
right: 30px;
bottom: 0;
line-height: 40px;
font-size: 14px;
color: #777777;
cursor: pointer;
z-index: 9;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.variant-selected{
  background-color:  #2e817e;
  border-radius: 5px;
  padding: 10px;
  margin-left: 10px;
}
.variant-selected > span{
  color:  white;
}
.variant{
  background-color: white;
  border-width: 1px;
  border-color: #2e817e;
  border-radius: 5px;
  padding: 10px;
  border-style: solid;
  margin-left: 10px;
}
.variant > span{
  color:  #2e817e;
}
.react-multiple-carousel__arrow--right{
  right: 0;
  top: 15vh;
}
.react-multiple-carousel__arrow--left{
  left: 0;
  top: 15vh;
}
.topbar {
  background-color: #fff;
  padding: 15px 0 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  /* border: 1px solid rgba(34, 36, 38, 0.15); */
  width: 100%;
  z-index: 999;
}
.topbar .ui.dropdown .menu {
  margin: 16px 0 0 0;
  max-height: 250px;
  border-radius: 0;
  overflow: hidden;
  overflow-y: auto;
  border-top: 4px solid #2e817e;
  border-bottom: 4px solid #2e817e;
}
.topbar .ui.dropdown .menu span {
  font-size: 13px;
}
.topbar .flex-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topbar .right-col {
  flex: 0 0 30%;
}
.topbar .right-col .ui.menu {
  background-color: transparent;
  color: #2e817e;
  justify-content: flex-end;
  box-shadow: none;
  border: none;
}
.topbar .right-col .ui.menu .item:before {
  display: none;
}
.topbar .right-col .ui.menu .item {
  color: #2e817e;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
}
.topbar .right-col .ui.menu .item:hover {
  color: #2e817e;
}
.topbar .right-col .ui.menu i {
  color: #143f3b;
}
.topbar .right-col .badge {
  background: #143f3b;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px;
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 10px;
  line-height: 15px;
}
.topbar .right-col a {
  color: #143f3b;
  font-size: 14px;
  font-weight: 400;
}
.topbar .right-col a:hover {
  color: #2e817e;
}
.topbar .right-col .dropdown a {
  color: #151515;
}
.topbar .left-col {
  flex: 0 0 30%;
  display: flex;
  align-items: center;
}
.topbar .left-col .logo-img {
  margin-right: 10px;
}
.topbar .left-col .avatar {
  width: 100px;
  height: auto;
  border-radius: 0;
}
.topbar .left-col .ui.dropdown {
  display: flex;
  align-items: center;
}
.topbar .left-col .selected {
  color: #143f3b;
}
.topbar .left-col .str-name {
  font-size: 10px !important;
  color: #143f3b;
}
.topbar .left-col .icon {
  color: #143f3b;
  font-size: 16px;
}
.topbar .search-col {
  flex: 0 0 40%;
}
.topbar .search-col .ui.input {
  width: 100%;
}
.topbar .search-col .ui.input > input {
  border: 1px solid #eaf0f4;
}
.topbar .search-col .ui.search .prompt {
  border-radius: 4px;
  background-color: #eaf0f4;
}
.topbar .menubar {
  padding-top: 20px;
}
.topbar .menubar .ui.secondary.pointing.menu .item {
  color: #4D4F5C;
  text-transform: uppercase;
  font-size: 13px;
  margin: 0;
  padding: 8px 0;
  margin-right: 30px;
}
.topbar .menubar .ui.secondary.pointing.menu .active.item {
  border-color: #2e817e;
}
.topbar .menubar .ui.secondary.pointing.menu {
  border: none;
}
.ui.secondary.pointing.menu{
  border: none;
}
.topbar .left-col .selected span {
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}
.ui.search .prompt::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #4D4F5C;
}
.ui.search .prompt::-moz-placeholder {
  /* Firefox 19+ */
  color: #4D4F5C;
}
.ui.search .prompt:-ms-input-placeholder {
  /* IE 10+ */
  color: #4D4F5C;
}
.ui.search .prompt:-moz-placeholder {
  /* Firefox 18- */
  color: #4D4F5C;
}
@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}
.navbar-sticky {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
  width: 100%;
}
.bottom-bar {
  margin: 5px 0 30px;
}
.bottom-bar .ui.attached.menu:not(.tabular) {
  border: 1px solid #2e817e;
  border-radius: 0;
}
.bottom-bar .ui.top.attached.menu > .item:first-child {
  background-color: #2e817e;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
}
.bottom-bar .ui.menu .ui.dropdown .menu > .active.item {
  border-left: 3px solid #2e817e;
}
.pm-padding {
  padding: 60px 0;
}
.sect-tile h3 {
  color: #4D4F5C;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}
.footer-wrapper {
  background-color: #103c3b;
  padding: 60px 0;
}
.footer-wrapper .f-head {
  font-size: 13px;
  color: #838d93;
  text-transform: uppercase;
}
.footer-wrapper .downloads .dwd {
  margin-bottom: 15px;
  max-width: 170px;
  display: block;
  height: auto;
}
.footer-wrapper .f-links a {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Rubik';
  transition: all 0.5s;
  margin: 2px 0;
  display: block;
}
.footer-wrapper .f-links a:hover {
  color: #2e817e;
}
.copy-wrapper {
  padding: 20px 0;
  background-color: #103c3b;
  text-align: center;
  border-top: 1px solid #445858;
}
.copy-wrapper p {
  margin: 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 300;
}
.home-slider {
  padding-top: 40px;
}
.home-slider .slick-list {
  padding-left: 0px!important;
}
.home-slider .sliding-banner {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.categories .slick-track{
  /* width: auto !important; */
  justify-content: flex-start;
}
.home-slider .slick-slide img {
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  object-fit: cover;
  height: 100%;
}
.BannerWrapper .banner-home {
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BannerWrapper img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.flex-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-title h2 {
  color: #3c3a36;
  font-weight: 700;
  margin: 0;
  font-size: 22px;
}
.flex-title .right-col a {
  color: #3c3a36;
  font-weight: 600;
  margin: 0;
  font-size: 15px;
  cursor: pointer;
}
.flex-title .right-col a:hover {
  color: #2e817e;
}
.ts-btn {
  color: #2e817e;
  border: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
  background-color: transparent;
}
.ts-btn:hover {
  color: #3c3a36;
}
.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 10px;
}
.cat-slider .category-box {
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.cat-slider .category-box h3 {
  padding: 0 20px;
  color: #373a36;
  position: absolute;
  bottom: 50px;
  text-align: start;
  left: 0;
  right: 0;
}
.cat-slider .category-box img {
  margin: 0 auto;
  border-radius: 4px;
}
.cat-slider .category-box:hover h3 {
  color: #2e817e;
}
.slick-prev:before,
.slick-next:before,
.react-multiple-carousel__arrow:before {
  color: #4D4F5C;
  opacity: 1;
  font-size: 18px;
}
.slick-arrow,
.react-multiple-carousel__arrow {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  width: 45px;
  height: 45px;
  z-index: 9;
  border-radius: 50%;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.react-multiple-carousel__arrow:hover,
.react-multiple-carousel__arrow:focus,
.slick-next:focus {
  color: #ffffff;
  outline: none;
  background: #2e817e;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1;
}
.product-slider .slick-track {
  display: flex !important;
  width: auto !important;
}
.product-slider .slick-slide {
  height: inherit !important;
  background: #ffffff;
  border-radius: 4px;
  /* max-width: 250px !important;
  width: 220px !important; */
}
.product-slider .slick-slide {
  margin-right: 5px;
  margin-left: 5px;
}
.product-slider .slick-slide > div {
  padding: 0;
}
.Product-box {
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  justify-content: space-between;
}
.Product-box img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.img-v {
  width: 30vh;
  height: 20vh;
  object-fit: cover;
}
/* .img-h{
  max-height: 148px !important;
} */
.Product-box .offer {
  position: absolute;
  top: 0;
  left: 0;
}
.Product-box .offer img {
  border-top-left-radius: 4px;
}
.Product-box .offer .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  font-weight: 500;
  font-size: 12px;
}
.Product-box .offer .centered .per {
  font-size: 16px;
  font-weight: 700;
  color: white;
}
.Product-box .card-footer {
  border: none;
  padding: 0 1.25rem 1.25rem;
  background-color: transparent;
}
.Product-box img.ui.image {
  max-width: 245px;
  margin: 0 auto;
  height: auto;
}
.Product-box .p-title {
  margin: 8px 0 0 0;
  font-weight: 500;
  line-height: 1.2;
  height: 45px;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.t-h{
  height: 60px !important;
}
.price-wrapper {
  padding: 5px 0;
}
.price-wrapper .price {
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}
.price-wrapper .old-price {
  position: relative;
  margin-left: 5px;
}
.price-wrapper .old-price:before {
  border-bottom: 1px solid #f86337;
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  top: 0;
}
.flex-hr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.yellow-btn {
  background-color: #2e817e;
  color: #ffffff;
  width: 100%;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  transition: all 0.5s;
}
.yellow-btn:hover {
  opacity: 0.75;
}
.yellow-btn .zmdi {
  float: right;
  font-weight: 700;
}
.my-cart-table .flex-hr {
  padding-right: 15px;
}
.my-cart-table .prodcut-list .rightox {
  flex: 0 0 75%;
}
.my-cart-table .prodcut-list .left-box {
  margin-right: 0;
  text-align: center;
  flex: 0 0 25%;
}
.my-cart-table .Product-box img {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-width: 130px;
  margin: 0 auto;
}
.drp-btn {
  background-color: #ffffff;
  border: none;
  color: #8e909e;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: none;
}
.drp-btn .zmdi {
  font-size: 18px;
  margin-left: 5px;
}
.wild-btn {
  background-color: #2e817e !important;
  color: #ffffff !important;
  padding: 10px 24px !important;
  border-radius: 6px !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  cursor: pointer;
  transition: all 0.5s;
}
.wild-btn:hover {
  opacity: 0.75;
}
.row-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-border {
  background-color: #ffffff;
  border: 1px solid #2e817e;
  color: #8e909e;
  padding: 8px 24px;
  border-radius: 4px;
  font-weight: 600;
  box-shadow: none;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.5s;
}
.btn-border:hover {
  background-color: #2e817e;
  color: #ffffff;
}
.edit-profile {
  background-color: #fff;
  padding: 15px;
  margin-top: -2px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}
.edit-profile .btn-border {
  background-color: transparent;
  color: #2e817e;
  padding: 8px 36px;
}
.edit-profile .btn-border:hover {
  background-color: #2e817e;
  color: #ffffff;
}
.edit-profile .flex-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-profile .flex-profile .name-img {
  display: flex;
  align-items: center;
}
.edit-profile .flex-profile .name-img p {
  color: #2e817e;
  margin: 10px 25px;
  font-size: 16px;
}
.edit-profile .flex-profile .name-img img {
  max-width: 100px;
}
.slick-prev:before,
.slick-next:before {
  display: inline-block;
  opacity: 1;
  margin: 0 0.25rem 0 0;
  width: 1.18em;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  speak: none;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.slick-prev:before {
  content: "\f060" !important;
}
.slick-next:before {
  content: "\f061" !important;
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  border-color: #2e817e !important;
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: #2e817e !important;
}
.ui.checkbox .box:before,
.ui.checkbox label:before {
  border-color: #2e817e !important;
}
.filter-bar {
  position: relative;
  height: calc(100vh - 100px);
  width: 280px;
  background-color: #ffffff;
  background: #fff;
  border: 1px solid #dedddd;
  border-radius: 4px;
}
.filter-bar .ui.form .grouped.fields {
  max-height: 375px;
  overflow-x: hidden;
  overflow-y: auto;
}
.filter-bar .yellow-btn {
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  text-align: center;
  border-radius: 0;
}
.filter-bar .flex-title {
  padding: 0.92857143em 1.14285714em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.filter-bar .ui.vertical.menu {
  width: 100%;
  border: none;
  box-shadow: none;
  margin-bottom: 60px;
}
.filter-bar .ui.accordion.menu .item .title {
  color: #3c3a36;
  font-weight: 700;
  font-size: 16px;
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  border-color: #727671;
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: #727671;
}
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #ffffff;
}
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:before {
  background: #727671;
  border-color: #727671;
}
.ui.checkbox .box:before,
.ui.checkbox label:before {
  border: 1px solid #727671;
}
.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  border-color: #727671;
}
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:after {
  color: #ffffff;
}
.two-rows-wrapper {
  padding-top: 30px;
}
.two-rows-wrapper .flex-grid {
  display: flex;
  margin: 0 -5px;
  padding-top: 20px;
}
.two-rows-wrapper .f-col {
  margin: 0 5px;
}
.two-rows-wrapper .left-column {
  flex: 0 0 280px;
}
.two-rows-wrapper .right-column {
  width: calc(100% - 280px);
}
.product-grid-area {
  /* flex: 0 0 calc(100% - 280px); */
  /* width: calc(100% - 280px); */
  min-height: calc(100vh - 68vh);
  padding: 15px 0;
  width: 100%;
}
.breadcrumb {
  background-color: transparent;
  padding: 0;
}
.ui.breadcrumb a.section {
  color: #3c3a36;
}
.ui.breadcrumb a.section:hover {
  color: #2e817e;
}
.bread-crumb {
  margin-bottom: 20px;
}
.b-shadow {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dedddd;
}
.prodcut-list {
  position: relative;
  display: flex;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 20px;
  flex: 0 0 100%;
  width: 100%;
  height: 150px;
  justify-content: flex-start;
}
.prodcut-list .offer img {
  border-top-left-radius: 4px;
}
.prodcut-list .left-box {
  margin-right: 0;
  flex: 0 0 40%;
  max-width: 150px;
}
.prodcut-list .rightox {
  flex: 0 0 60%;
}
.prodcut-list img.ui.image {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  max-width: 100%;
}
.list-products .rightox {
  /* padding: 20px !important; */
}
.list-products .rightox .btn-wrap {
  text-align: right;
  padding: 10px 0 5px 0;
}
[class*="col-"] {
  padding: 0 10px;
}
.row {
  margin-left: -10px;
  margin-right: -10px;
}
.horizontal-menu {
  border-bottom: 2px solid #2e817e;
  margin-bottom: 40px;
  background-color: #ffffff;
  padding: 15px 10px;
}
.menu-item {
  padding: 0 30px;
  margin: 0;
  user-select: none;
  cursor: pointer;
  border: none;
  color: #4D4F5C;
}
.menu-item.active {
  color: #2e817e;
  font-weight: 700;
}
.menu-item.active:after {
  display: block;
  content: '';
  height: 3px;
  position: relative;
}
.scroll-menu-arrow {
  cursor: pointer;
  color: #4D4F5C;
  font-size: 20px;
  font-weight: 700;
}
:focus {
  outline: none;
}
.small-text {
  color: #4D4F5C;
  font-size: 12px;
}
.large-text {
  color: #3c3a36;
  font-size: 16px;
  font-weight: 500;
}
.big-text {
  font-size: 20px;
  color: #3c3a36;
  font-weight: 700;
}
.big-text .cr {
  font-size: 16px;
}
.order-box {
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #dedddd;
  padding: 20px;
}
.order-box .status {
  padding: 5px 22px;
  border-radius: 4px;
  color: #fff;
  background-color: #9f9ea5;
  display: inline-block;
}
.order-box .order-head {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-box .order-head .left-head {
  flex: 0 0 60%;
}
.order-box .order-head .flext-h {
  display: flex;
  align-items: flex-end;
}
.order-box .order-head .flext-h .ts-btn {
  margin-left: 20px;
}
.order-box .btn-wrapper {
  border-top: 1px dashed #dedddd;
  padding-top: 20px;
}
.order-box .btn-wrapper button:first-child {
  margin-right: 15px;
}
.bg-yellow {
  background-color: #2e817e !important;
}
.bg-green {
  background-color: #5ee2a0 !important;
}
.bg-red {
  background-color: #ff8474 !important;
}
.side-menu-wrapper {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 25px 0;
  border: 1px solid #dedddd;
}
.side-menu-wrapper .ui.vertical.tabular.menu {
  border-right: none;
}
.side-menu-wrapper .ui.vertical.tabular.menu .item {
  border-left: 4px solid transparent;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0 !important;
  font-size: 16px;
  color: #b4b4b4;
}
.side-menu-wrapper .ui.vertical.menu .item > i.icon {
  float: left;
  margin: 0 10px 0 0;
}
.side-menu-wrapper .ui.vertical.tabular.menu .active.item {
  border-color: #2e817e;
  font-weight: 500;
  color: #3c3a36;
  background: transparent;
}
.Points-wrapper {
  background-color: #ffffff;
  padding: 40px 25px 60px 25px;
  border-radius: 4px;
  border: 1px solid #dedddd;
}
.Points-wrapper .point-img-wrap {
  position: relative;
  text-align: center;
}
.Points-wrapper .point-img-wrap .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Points-wrapper .point-img-wrap .point {
  font-size: 42px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 10px;
}
.Points-wrapper .point-img-wrap p {
  font-size: 18px;
  letter-spacing: 3px;
  color: #ffffff;
  margin: 0;
  font-weight: 300;
}
.Points-wrapper .short-dis {
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}
.Points-wrapper .short-dis p {
  color: #4D4F5C;
  font-weight: 300;
  font-size: 16px;
}
.page-anchors {
  padding-top: 10px;
}
.page-anchors .btn-border {
  display: block;
  max-width: 280px;
  text-align: center;
  margin: 15px auto;
  font-weight: 500;
  box-shadow: none;
  font-size: 15px;
}
.action-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.savedAdress .address-box {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #dedddd;
  margin-bottom: 20px;
  width: 100%;
}
.savedAdress .address-box .large-text {
  font-weight: 400;
  margin-top: 10px;
}
.savedAdress .address-box .large-text p {
  margin-bottom: 0;
  color: #000000;
  line-height: 1.4;
  font-size: 15px;
}
.savedAdress .address-box .btn-wrapper {
  margin-top: 15px;
}
.savedAdress .button {
  background-color: transparent;
  border: none;
  color: #4D4F5C;
  padding: 6px 0;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: none;
}
.savedAdress .button:hover {
  background-color: transparent;
  color: #2e817e;
}
.modals h3 {
  font-size: 16px;
  font-weight: 500;
  color: #4D4F5C;
}
.note-box {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid #dedddd;
  margin-bottom: 20px;
}
.note-box .grey-text {
  padding-top: 10px;
}
.note-box .date {
  font-weight: 300;
  color: #3c3a36;
  padding-left: 10px;
}
.note-box p {
  margin-bottom: 0;
  color: #838d93;
  line-height: 1.6;
}
.group-actions .icon {
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
}
.group-actions .trash {
  color: #ff8474;
}
.group-actions .write {
  color: #5ee2a0;
}
.faq-content-wrapper .ui.styled.accordion,
.faq-content-wrapper .ui.styled.accordion .accordion {
  border: 1px solid #dedddd;
  box-shadow: none;
}
.faq-content-wrapper .ui.styled.accordion .accordion .title,
.faq-content-wrapper .ui.styled.accordion .title {
  color: #3c3a36;
  font-size: 16px;
  padding: 1.75em 1em;
}
.faq-content-wrapper .ui.styled.accordion .content {
  color: #4D4F5C;
  font-size: 14px;
  padding: 0.5em 1.5em 1.5em;
  font-weight: 300;
}
.cart-wrapper {
  padding-top: 40px;
}
.flex-row > [class*='col-'] {
  display: -ms-flexbox;
  display: flex;
}
.cart-item .btn-wrap {
  width: auto;
}
.cart-item .rightox {
  padding: 20px 0 20px 20px;
}
.cart-item .group-actions {
  position: absolute;
  right: 15px;
  top: 15px;
}
.item-count {
  display: flex;
  align-items: center;
}
.item-count .form-control {
  height: calc(2rem + 2px);
  margin: 0 5px;
  width: 45px;
  text-align: center;
}
.price-bord {
  background-color: #fff;
  padding: 30px 15px;
}
.price-bord .yellow-btn {
  padding: 10px 20px;
}
.price-bord .ui.table {
  border-radius: 0;
  border: none;
  margin: 0;
}
.price-bord .light-text {
  color: #b1b2ba !important;
  font-size: 13px !important;
}
.price-bord .ui.fixed.table th {
  text-transform: uppercase;
  font-weight: 600;
  color: #878787;
  min-height: 47px;
  border-radius: 2px 2px 0 0;
  border: none;
}
.price-bord .ui.fixed.table td {
  color: #212121;
  line-height: 1.4;
  font-size: 15px;
  border: none;
  padding: 8px;
}
.price-bord .free {
  color: #4AD991 !important;
}
.free{
  color: #2e817e !important;
}
.price-bord td.final {
  font-weight: 600;
  font-size: 18px;
}
.btop {
  border-top: 1px solid #dee2e6 !important;
  margin-bottom: 20px !important;
}
.checkout-wrapper .ui.styled.accordion,
.checkout-wrapper .ui.styled.accordion .accordion {
  box-shadow: none;
  background: transparent;
}
.center-marker{
  position: absolute;
  left: 46%;
  top: 102px;
}
.checkout-wrapper .ui.styled.accordion .accordion .title,
.checkout-wrapper .ui.styled.accordion .title {
  font-size: 18px;
  padding: 1em 0.5em;
  background-color: #fff;
  border-top: none;
}
.checkout-wrapper i.icon {
  color: #2e817e;
  margin-right: 10px;
}
.checkout-wrapper .ui.styled.accordion .accordion .content,
.checkout-wrapper .ui.styled.accordion .content {
  margin: 0 0 20px 0;
  padding: 2.5em 1em 2.5em;
  background-color: #fff;
}
.login-accord .yellow-btn {
  max-width: 130px;
  text-align: center;
  padding: 8px 26px;
}
.login-wrapper{
  align-items: center;
  display: flex;
}
.login-accord .yellow-btn:hover {
  color: white;
}
.time-slote-wrapper .choose-slot {
  margin-bottom: 20px;
  border-bottom: 1px solid #dedddd;
  padding-bottom: 20px;
}
.time-slote-wrapper .btn-wrapper {
  padding: 15px 0;
}
.time-slote-wrapper .type-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.time-slote-wrapper .slot-btn {
  background-color: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #4D4F5C;
  padding: 6px 18px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: none;
  width: 100px;
}
.checkout-wrapper .ui.styled.accordion .title {
  margin-bottom: 20px;
}
.time-slote-wrapper .slot-btn:hover {
  background-color: #4AD991;
  border: 1px solid #4AD991;
  color: #ffffff;
}
.time-slote-wrapper .slot-btn.active {
  background-color: #4AD991;
  border: 1px solid #4AD991;
  color: #ffffff;
}
.time-slote-wrapper .slot-picker {
  margin: 15px 0;
}
.time-slote-wrapper .slot-picker .slick-arrow {
  box-shadow: none;
  width: 20px;
  height: 20px;
  border-radius: 0;
}
.time-slote-wrapper .slot-picker .slick-prev:before,
.time-slote-wrapper .slot-picker .slick-next:before {
  font-size: 14px;
  color: #c0c0c7;
}
.time-slote-wrapper .slot-picker .slick-prev {
  left: -10px;
}
.time-slote-wrapper .slot-picker .slick-next {
  right: -10px;
}
button:focus {
  outline: none;
}
.btn-ct {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-ct .zmdi {
  margin-left: 0 !important;
}
.ct-value {
  margin: 0 15px;
}
.pay-wrapper {
  border: none;
}
.pay-wrapper .ui.vertical.tabular.menu {
  border-right: none;
}
.pay-wrapper .ui.vertical.tabular.menu .item {
  border-left: 4px solid transparent;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0 !important;
  font-size: 15px;
  color: #b4b4b4;
  font-weight: 700;
}
.pay-wrapper .ui.vertical.menu .item > i.icon {
  float: left;
  margin: 0 10px 0 0;
}
.pay-wrapper .ui.vertical.tabular.menu .active.item {
  border-color: #2e817e;
  color: #3c3a36;
  background: transparent;
}
.pay-wrapper .ui.attached.segment {
  border: none;
}
.empty-product {
  text-align: center;
  padding-bottom: 50px;
}
.empty-product img {
  margin-bottom: 20px;
}
.thankyou-wrapper .white-wrapper {
  background-color: #ffffff;
  max-width: 980px;
  margin: 0 auto;
  padding: 50px;
}
.thankyou-wrapper .Thk-product {
  text-align: center;
  padding: 50px 0;
}
.thankyou-wrapper .Thk-product img {
  margin-bottom: 20px;
}
.thankyou-wrapper .more-details {
  text-align: center;
}
.thankyou-wrapper .more-details .new-shopping {
  margin-top: 25px;
}
.profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #2e817e;
}
.ui.search > .results {
  width: 100%;
}
.white-wrapper {
  padding: 80px 0;
  background-color: #fff;
  min-height: 60vh;
}
.sec-title {
  margin-bottom: 30px;
  display: inline-block;
}
.sec-title h2 {
  font-size: 28px;
}
.sec-title h2:after {
  background-color: #2e817e;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
}
.yellow-btn .zmdi {
  margin-left: 5px;
}
.Product-details {
  padding: 60px 0;
}
.Product-details .thumbnail-slider-wrap {
  margin-top: 15px;
  height: 85px;
}
.Product-details .thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
  cursor: pointer;
}
.Product-details .thumbnail-slider-wrap .slick-track .slick-slide img {
  max-width: 100px;
  border-radius: 8px;
  background-color: #ffffff;
}
.Product-details .product-image {
  min-height: 380px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
}
.Product-details .p-info {
  background: #ffffff;
  padding: 25px;
  border-radius: 8px;
}
.Product-details .p-info .fl-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Product-details .p-info .yellow-btn {
  width: auto;
}
.Product-details .p-info .info-row {
  border-bottom: 1px solid #b4b4b7;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.Product-details .p-info h5 {
  margin-bottom: 5px;
  color: #000000;
  font-weight: 600;
}
.Product-details .p-info .offer {
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #2e817e;
  color: #3c3a36;
  padding: 3px 10px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}
.Product-details .p-info .font-18 {
  font-size: 20px;
  margin: 5px 0 10px 0;
}
.Product-details .p-info .price-wrapper .price {
  font-size: 24px;
}
.Product-details .p-info .price-wrapper .old-price {
  font-size: 18px;
}
.Product-details .info-row:last-child {
  border: none;
}
.brd-wrap {
  padding: 5px 0 30px;
}
@media only screen and (min-width: 1440px) {
  .container {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 1366px) {
  .container {
    max-width: 1200px;
  }
  .slot-picker .slick-track{
    width: auto !important;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 768px) {
  .search-mob {
    display: none !important;
  }
  .categories .slick-track{
    width: auto !important;
  }
  
}
@media only screen and (max-width: 992px) {
  .menubar{
    margin-top: 10px;
  }
  .two-rows-wrapper .flex-grid {
    display: block;
    padding-top: 0;
  }
  .filter-bar {
    height: auto;
  }
  .product-grid-area {
    flex: 0 0 100%;
    width: 100%;
    min-height: calc(100vh - 90px);
    padding: 15px 0;
  }
  .two-rows-wrapper .f-col,
  .two-rows-wrapper .flex-grid {
    margin: 0 0 20px;
    display: block;
  }
  .filter-bar .ui.form .grouped.fields {
    max-height: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .topbar .left-col {
    flex: 0 0 50%;
  }
  .home-slider .slick-slide img {
    height: auto;
  }
  .ts-btn {
    font-size: 14px;
  }
  .cart-item .rightox {
    padding: 10px;
  }
  .drp-btn .zmdi {
    font-size: 15px;
    }
  .hide-text span {
    font-size: 0 !important;
  }
  .topbar .right-col {
    flex: 0 0 50%;
  }
  .topbar .right-col .ui.menu .item {
    padding: 10px 0 10px 15px;
  }
  .topbar .search-col {
    flex: 0 0 100%;
    order: 3;
    position: fixed;
    top: 75px;
    left: 0;
    background: #373a36;
    right: 0;
    padding: 15px;
  }
  .topbar .search-col {
    flex: 0 0 100%;
    order: 3;
    position: fixed;
    top: 55px;
    left: 0;
    background: #373a36;
    right: 0;
    padding: 15px;
    display: none;
  }
  .view-search {
    display: block !important;
  }
  .copy-wrapper p {
    font-size: 12px;
  }
  .flex-title h2 {
    font-size: 20px;
  }
  .pb-50 {
    padding-bottom: 40px;
  }
  .footer-wrapper .f-head,
  .flex-title .right-col a {
    font-size: 15px;
  }
  .footer-wrapper {
    padding: 40px 0;
    text-align: center;
  }
  .footer-wrapper .f-links {
    margin-bottom: 20px !important;
  }
  .footer-wrapper .downloads .dwd {
    margin: 0 auto;
    max-width: 140px;
    margin-bottom: 15px;
  }
  .footer-wrapper .f-links a {
    font-size: 14px;
  }
  .product-slider .slick-prev,
  .product-slider .react-multiple-carousel__arrow,
  .product-slider .slick-next {
    top: 25%;
  }
  .react-multiple-carousel__arrow--right{
    right: 0px;
  }
  .react-multiple-carousel__arrow--left{
    left: 0px;
  }
  .product-slider .slick-slide img {
    max-width: 220px;
    margin: 0 auto;
  }
  .BannerWrapper .banner-home {
  }
  .home-slider .sliding-banner {
    height: auto;
  }
  .font-18 {
    font-size: 15px;
  }
  .Product-box .offer .centered .per {
    font-size: 14px;
  }
  .cat-slider .category-box h3 {
    bottom: 50px;
    text-align: start;
    font-size: 14px;
  }
  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
  .product-slider .slick-slider {
    margin: 0;
  }
  .slick-arrow {
    box-shadow: none;
    width: 30px;
    height: 30px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 16px;
  }
  .topbar .left-col .selected span {
    width: 100px;
  }
  .logo-img {
    flex: 0 0 15%;
    margin-right: 5px;
  }
  .ui.dropdown > .text {
    font-size: 15px;
  }
  .topbar .ui.dropdown .menu {
    margin: 10px 0 0 0;
  }
  .body-wrapper {
    padding-top: 60px;
    min-height: calc(100vh - 39vh);
  }
  .mb-30 {
    margin-bottom: 20px;
  }
  .ui.dropdown > .dropdown.icon {
    margin: 0 0 0 5px;
  }
  .right-col .dropdown {
    font-size: 12px !important;
  }
  .topbar .right-col .badge {
    width: 18px;
    height: 18px;
    top: 0px;
    font-size: 8px;
    line-height: 15px;
  }
  .horizontal-menu {
    margin-bottom: 20px;
    padding: 10px;
  }
  .menu-item {
    padding: 0 20px;
  }
  .cart-wrapper {
    padding-top: 40px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
  .checkout-wrapper .ui.styled.accordion .accordion .title,
  .checkout-wrapper .ui.styled.accordion .title {
    font-size: 15px;
  }
  .pay-wrapper .ui.grid {
    display: block;
  }
  .pay-wrapper .ui.grid > [class*="four wide"].column {
    width: 100%!important;
  }
  .pay-wrapper .ui.grid > [class*="twelve wide"].column {
    width: 100%!important;
  }
  .pay-wrapper .ui.vertical.tabular.menu .item {
    font-size: 14px;
    padding: 10px;
  }
  .ui.segment {
    padding: 0 1em 1em;
  }
  .cart-wrapper .price-bord {
    margin-top: 10px;
  }
  .checkout-wrapper .ui.styled.accordion .accordion .content,
  .checkout-wrapper .ui.styled.accordion .content {
    margin: 0;
  }
  .edit-profile .flex-profile .name-img p {
    margin: 6px 25px;
    font-size: 14px;
  }
  .two-rows-wrapper .right-column {
    width: 100%;
  }
  .large-text {
    font-size: 13px;
  }
  .small-text {
    font-size: 10px;
  }
  .big-text {
    font-size: 15px;
  }
  .order-box .order-head .flext-h .ts-btn {
    margin-left: 7px;
  }
  .order-box {
    padding: 15px;
    margin-bottom: 20px;
  }
  .order-box .ts-btn {
    font-size: 10px;
  }
  .order-box .wild-btn {
    padding: 8px 16px !important;
    font-size: 13px !important;
  }
  .order-box .status {
    padding: 5px 10px;
    font-size: 10px;
    height: auto !important;
  }
  .page-anchors .btn-border {
    font-size: 14px;
  }
  .side-menu-wrapper .ui.vertical.tabular.menu .item {
    font-size: 16px;
  }
  .Product-details {
    padding: 40px 0;
  }
  .Product-details .product-image {
    min-height: 300px;
  }
  .Product-details .thumbnail-slider-wrap .slick-track .slick-slide img {
    max-width: 75px;
  }
  .Product-details .p-info {
    padding: 15px;
  }
  .Product-details .p-info .font-18 {
    font-size: 17px;
  }
  .yellow-btn {
    font-size: 14px;
  }
  .Product-details .p-info .price-wrapper .price {
    font-size: 20px;
  }
  .Product-details .p-info .price-wrapper .old-price {
    font-size: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .filter-bar {
    width: 100%;
  }
  .savedAdress .address-box .large-text p {
    font-size: 13px;
  }
  .note-box .date {
    font-size: 10px;
  }
  .checkout-wrapper .ui.styled.accordion .accordion .content,
  .checkout-wrapper .ui.styled.accordion .content {
    padding: 1em;
  }
  .checkout-wrapper .ui.styled.accordion .accordion .title,
  .checkout-wrapper .ui.styled.accordion .title {
    font-size: 15px;
  }
  .cart-wrapper {
    padding-top: 30px;
  }
  .topbar .left-col .selected {
    font-size: 12px;
  }
  .topbar .left-col .str-name {
    font-size: 10px;
  }
  .topbar .left-col .avatar {
    width: 25px;
    height: 25px;
  }
  .ui.dropdown > .text,
  .ui.menu .ui.dropdown .menu > .item {
    font-size: 14px !important;
  }
  .topbar .right-col .ui.menu i {
    font-size: 15px;
  }
  .topbar .ui.dropdown .menu {
    max-height: 200px;
  }
  .my-cart-table .prodcut-list .left-box {
    flex :0 0 35%;
  }
  .my-cart-table .prodcut-list .rightox {
    flex: 0 0 65%;
    }
  .prodcut-list {
    margin-bottom: 20px;
  }
  .prodcut-list .left-box img {
    max-width: 100%;
    margin: 0 auto;
  }
  .prodcut-list{
    height: auto;
  }
  .price-wrapper {
    padding: 2px 0;
    }
    .price-wrapper .price {
      font-size: 14px;
    }
    .Product-box .p-title {
      margin: 5px 0 5px 0;
      height: auto; 
      font-size: 13px;
      }
    .font-16 {
      font-size: 12px;
      }
      .my-cart-table .flex-hr {
        padding-right: 0;
      }
      .ct-value {
        margin: 0 10px;
        }
        .btn-ct {
          width: 25px;
          height: 25px;
          }
  .prodcut-list .left-box {
    margin-right: 0;
    flex: 0 0 35%;
    text-align: center;
    max-width: 100%;
  }
  .prodcut-list .rightox {
    height: auto;
    flex: 0 0 65%;
  }
  .pb-50 {
    padding-bottom: 30px;
  }
}

.MuiDrawer-paperAnchorLeft{
  max-width: 90%;
  min-width: 280px;
  width: 400px;
}
